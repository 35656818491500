import { Table as CustomTable } from 'antd';
import TableEmpty from 'common/images/TableEmpty.svg';
import styled, { css } from 'styled-components';
import Title from './Typography/Title';
import Loader from './Loader/Loader';

const Table = ({
  columns,
  wrapperStyle,
  dataSource,
  pagination,
  minWidth,
  bg,
  ...props
}) => {
  return (
    <Table.Wrapper $wrapperStyle={wrapperStyle} $bg={bg} minWidth={minWidth}>
      {props.loading ? (
        <Loader />
      ) : (
        <CustomTable
          components={{
            header: {
              row: ({ children, ...props }) => {
                return <tr {...props}>{children}</tr>;
              },
              cell: ({ children, ...props }) => {
                return (
                  <td {...props} className='header_bg'>
                    {children}
                  </td>
                );
              }
            },
            body: {
              row: ({ children, ...props }) => {
                return (
                  <tr {...props} className='body_row'>
                    {children}
                  </tr>
                );
              },
              cell: ({ children, colSpan, ...props }) => {
                return (
                  <td
                    {...props}
                    className='body_bg'
                    colSpan={colSpan}
                    style={{ verticalAlign: props?.textAlign ?? 'middle' }}
                  >
                    {children}
                  </td>
                );
              }
            }
          }}
          columns={columns}
          pagination={pagination}
          dataSource={dataSource}
          {...props}
        />
      )}
    </Table.Wrapper>
  );
};

export default Table;

Table.Wrapper = styled.div`
  --table-bg: ${(props) => props.$bg ?? '#fafafa'};
  margin: 1rem 0;
  overflow-x: auto;
  ${(props) =>
    props.$wrapperStyle &&
    css`
      ${props.$wrapperStyle}
    `}

  table {
    border-collapse: seperate;
    border-spacing: 0 15px;
    ${(props) =>
      props.minWidth &&
      css`
        minwidth: ${props.minWidth};
      `}
  }

  .body_row {
    border-bottom: 1px solid #e1e1e1 !important;
  }
  .ant-pagination {
    li {
      padding: 0px;
    }
  }

  .header_bg {
    padding: 1rem;
    font-weight: 700;
    color: #545454;
    background: var(--table-bg);
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;

    :last-child {
      border-right: 1px solid #e1e1e1;
      border-radius: 0px 10px 0px 0px;
    }

    :first-child {
      border-left: 1px solid #e1e1e1;
      border-radius: 10px 0px 0px 0px;
    }
    input:checked {
      opacity: 1;
    }
    .ant-checkbox-inner {
      overflow: hidden !important;
    }
  }

  .body_bg {
    border-top: none !important;
    border-bottom: 1px solid #e1e1e1 !important;

    :last-child {
      border-right: 1px solid #e1e1e1;
    }

    :first-child {
      border-left: 1px solid #e1e1e1;
    }
  }
  .body_row > td:first-child {
    input:checked {
      opacity: 1;
    }
  }
  .ant-table-placeholder {
    width: 100%;
    text-align: center;
  }
  .antd-empty-table {
    height: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .empty-table-text {
    margin-top: 1rem;
  }
`;

export const EmptyState = ({ text, children }) => {
  return (
    <div className='antd-empty-table'>
      <div className='empty-table-icon'>
        <img src={TableEmpty} alt='empty icon' />
      </div>
      <Title className='empty-table-text'>{text}</Title>

      {children}
    </div>
  );
};
