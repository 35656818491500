import React from 'react';

import PlanRestrictionModal from './PlanRestrictionModal';
import usePlanRestrictionInterceptor from 'hooks/usePlanRestrictionInterceptor';
import useDisclosure from 'hooks/useDisclosure';

const PlanRestriction = () => {
  return <RestrictionSetup />;
};

export default PlanRestriction;

const RestrictionSetup = () => {
  const [opened, { toggle }] = useDisclosure();

  usePlanRestrictionInterceptor({ opened, toggle });
  return <PlanRestrictionModal opened={opened} toggle={toggle} />;
};
