import { getAllContacts, getRegions } from 'api/accounting';
import {
  addAsset,
  addAssetType,
  addAssignee,
  addAttachment,
  addIssueType,
  addNewMaintenance,
  addStatutoryDocument,
  createDocumentType,
  createIssueReport,
  createReminder,
  editAsset,
  editAssetType,
  editIssueType,
  getAllAssetTypes,
  getAsset,
  getAssetAttachments,
  getAssetMaintenance,
  getAssetReminders,
  getAssetReportedIssues,
  getAssetServiceAndRepair,
  getAssignees,
  getDocumentTypes,
  getIssueTypes,
  getMaintenances,
  getMaintenance,
  getMake,
  getMakeModel,
  getMakes,
  getOneAsset,
  getReportedIssues,
  getVehicleTypesList,
  recentlyAddedAsset,
  removeAsset,
  removeAssetImage,
  removeAssetType,
  removeAssignee,
  removeDocumentType,
  removeMaintenance,
  removeReminder,
  updateRenewal,
  uploadAssetImage,
  updateMaintenance,
  updateDocumentType,
  getAllAssetTypes2,
  importAssets,
  getVehicleList
} from 'api/asset';
import { uploadOnboardingDocs } from 'api/hr';
import mutateHOC from 'hooks/mutations/hoc';
import queryHOC from 'hooks/queries/hoc';
import { useQuery } from 'react-query';
export const keys = {
  ASSETTYPES: 'asset-asset-type',
  ASSETS: 'all-assets',
  RERGIONS: 'regios',
  REMINDERS: 'asset-reminder',
  MAINTENACE: 'maintenance',
  SINGLE_MAINTENACE: 'single-maintenance',
  SERVICES_REMINDER: 'maintenance',
  VEHICLE_MAKES: 'vehicle_make',
  VEHICLE_MAKE: 'vehicle_make',
  VEHICLE_MODEL: 'vehicle_model',
  CONTACTS: 'contacts',
  ISSUE_TYPES: 'issue_type',
  REPORTED_ISSUES: 'reported_issues',
  INDIVIDUAL_ASSET_MAINTENANCE: 'individual_asset_maintenance',
  ASSIGNEES: 'assignees',
  ALL_REPORTED_ISSUES: 'all_reported_issues',
  ASSET_ATTACHMENTS: 'asset_attachments',
  VEHICLE_TYPE_LIST: 'vehicle_type_list',
  ALL_DOCUMENT_TYPE: 'all_documents_type',
  GET_ASSETTYPES: 'get_all_asset_types',
  EMPLOYEES_HISTORY: 'employee_history',
  IMPORT_ASSETS: '/asset-management/assets/bulk_import/',
  VEHICLE_LIST: 'vehicle_list'
};

export const useCreateAssetType = mutateHOC({
  mutateFn: addAssetType,
  editFn: editAssetType,
  successMsg: (isEdit) => `Asset Type ${isEdit ? 'Edited' : 'Added'}...`
});
export const useCreateIssueType = mutateHOC({
  mutateFn: addIssueType,
  editFn: editIssueType,
  successMsg: (isEdit) => `Issue Type ${isEdit ? 'Edited' : 'Added'}...`
});

export const useFetchAssetTypes = (queries = {}, options = {}) => {
  return useQuery(
    [keys.ASSETTYPES, queries],
    () => getAllAssetTypes(queries),
    options
  );
};

export const useFetchAssetTypes2 = (queries = {}, options = {}) => {
  return useQuery(
    [keys.GET_ASSETTYPES, queries],
    () => getAllAssetTypes2(queries),
    options
  );
};

export const useFetchRegions = () => useQuery([keys.RERGIONS], getRegions);

export const useCreateAsset = mutateHOC({
  mutateFn: addAsset,
  editFn: editAsset,
  successMsg: (editId) => (editId ? 'Asset Edited' : 'Asset Added...')
});

export const useCreateAssetAttachment = mutateHOC({
  mutateFn: ({ files, progressSetter }) => {
    const formDataUpload = new FormData();
    formDataUpload.append('file', files);
    return addAttachment(formDataUpload, progressSetter);
  },
  successMsg: 'Attachment uploaded...'
});

export const useCreateAssetDocument = mutateHOC({
  mutateFn: ({ files, name, progressSetter }) => {
    const formDataUpload = new FormData();
    formDataUpload.append('file', files);
    return uploadOnboardingDocs(formDataUpload, progressSetter);
  },
  successMsg: 'Attachment uploaded...'
});

export const useFetchRecentlyAddedAssetType = () => {
  return useQuery([keys.RECENTLY_ADDED_ASSETS], recentlyAddedAsset);
};

export const useFetchAssetReminders = (params) => {
  return useQuery([keys.REMINDERS], () => {
    return Promise.all([
      getAssetReminders({ status: 'upcoming', search: params?.search }),
      getAssetReminders({ status: 'due', search: params?.search })
    ]);
  });
};

export const useFetchAllAssets = (params = {}, options = {}) => {
  return useQuery([keys.ASSETS, params], () => getAsset(params), options);
};

export const useFetchServiceAndRepair = (queries = {}) => {
  return useQuery([keys.SERVICES_REMINDER, queries], () =>
    getAssetServiceAndRepair(queries)
  );
};

export const useCreateAssetMaintenance = mutateHOC({
  mutateFn: addNewMaintenance,
  editFn: updateMaintenance,
  successMsg: (isEdit) =>
    isEdit ? 'Maintenance Edited ...' : 'Maintenance Added...'
});

/// individual asset

export const useFetchAsset = queryHOC('get-one-asset', getOneAsset);

export const useCreateAssetImage = mutateHOC({
  mutateFn: uploadAssetImage,
  successMsg: 'Image Uploaded ..'
});

export const useFetchMakes = (params = {}, options = {}) =>
  useQuery([keys.VEHICLE_MAKES, params], () => getMakes(params), options);

export const useFetchMake = (params = {}, options = {}) =>
  useQuery([keys.VEHICLE_MAKE, params], () => getMake(params), options);

export const useFetchMakeModel = (params, options = {}) =>
  useQuery([keys.VEHICLE_MODEL, params], () => getMakeModel(params), options);

export const useFetchReminders = queryHOC(keys.REMINDERS, (_, params) => {
  return Promise.all([
    getAssetReminders({ status: 'upcoming', search: params?.search }),
    getAssetReminders({ status: 'due', search: params?.search })
  ]);
});

export const useRemoveAssign = mutateHOC({
  mutateFn: removeAssignee,
  successMsg: 'Asset Unassigned'
});

export const useCreateReminder = mutateHOC({
  mutateFn: createReminder,
  successMsg: 'Reminder Created..'
});
export const useCreateRenewal = mutateHOC({
  mutateFn: createReminder,
  editFn: updateRenewal,
  successMsg: 'Reminder Created..'
});

export const useFetchContacts = queryHOC(keys.CONTACTS, getAllContacts);

export const useCreateStatutoryDocument = mutateHOC({
  mutateFn: addStatutoryDocument,
  successMsg: 'Document Attached'
});

export const useFetchIssueType = queryHOC(keys.ISSUE_TYPES, getIssueTypes);
export const useFetchMaintenances = queryHOC(keys.MAINTENACE, getMaintenances);

export const useRemoveAssets = mutateHOC({
  mutateFn: removeAsset,
  successMsg: 'Asset removed ..'
});

export const useDeleteMaintenance = mutateHOC({
  mutateFn: removeMaintenance,
  successMsg: 'Maintenance removed ..'
});
export const useDeleteReminder = mutateHOC({
  mutateFn: removeReminder,
  successMsg: 'Reminder removed ..'
});
export const useCreateIssueReport = mutateHOC({
  mutateFn: createIssueReport,
  successMsg: 'Issue Reported ..'
});

export const useFetchReportedIssues = queryHOC(
  keys.REPORTED_ISSUES,
  getAssetReportedIssues
);

export const useGetAssetMaintenance = queryHOC(
  keys.MAINTENACE,
  getMaintenances
);

export const useGetAssignedEmployeeHistory = queryHOC(
  keys.EMPLOYEES_HISTORY,
  getAssignees
);

export const useUploadAssetImage = mutateHOC({
  mutateFn: uploadAssetImage,
  successMsg: 'image Uploaded'
});

export const useRemoveAssetImage = mutateHOC({
  mutateFn: removeAssetImage,
  successMsg: 'image Uploaded'
});

export const useAssignEmployee = mutateHOC({
  mutateFn: addAssignee,
  successMsg: 'User Assigned ..'
});

export const useAssetMaintenance = queryHOC(
  keys.INDIVIDUAL_ASSET_MAINTENANCE,
  getAssetMaintenance
);
export const useAssetAssignees = queryHOC(keys.ASSIGNEES, getAssignees);

export const useFetchAllReportedIssues = queryHOC(
  keys.ALL_REPORTED_ISSUES,
  getReportedIssues
);

export const useGetAssetAttachments = queryHOC(
  keys.ASSET_ATTACHMENTS,
  getAssetAttachments
);

export const useRemoveAssetType = mutateHOC({
  mutateFn: removeAssetType,
  successMsg: 'Asset Type Removed'
});
export const useVehicleTypeList = queryHOC(
  keys.VEHICLE_TYPE_LIST,
  getVehicleTypesList
);
export const useVehicleDetails = queryHOC(
  keys.VEHICLE_TYPE_LIST,
  getVehicleTypesList
);
export const useVehicleList = queryHOC(keys.VEHICLE_LIST, getVehicleList);

export const useGetDocumnentType = queryHOC(
  keys.ALL_DOCUMENT_TYPE,
  getDocumentTypes
);

export const useCreateDocumentType = mutateHOC({
  mutateFn: createDocumentType,
  editFn: updateDocumentType,
  successMsg: (isEdit) =>
    isEdit ? 'Statutory type Edited ..' : 'Statutory Type Added'
});

export const useRemoveDocumentType = mutateHOC({
  mutateFn: removeDocumentType,
  successMsg: 'Statutoru Type removed ...'
});

export const useGetSingleMaintenance = queryHOC(
  keys.SINGLE_MAINTENACE,
  getMaintenance
);
export const useImportAssets = mutateHOC({
  mutateFn: importAssets,
  successMsg: 'Asset imported successfully',
  errMsg: 'Error importing asset please check your'
});
