import { Box } from '@material-ui/core';
import { useState } from 'react';
import { CustomText } from 'common/Reusables';
import { Work } from '@material-ui/icons';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import styled from 'styled-components';
import JobDetail from './_JobDetail';
import PreviewApplication from 'Recruit/Jobs/CreateJob/PreviewApplication/_PreviewApplication';

import _ from 'lodash';

const SinglePageJobDetail = ({
  width,
  isPublic,
  hideApplicationButton,
  applicationForm,
  setApplicationForm,
  formik,
  data
}) => {
  const [activeJobPane, setActiveJobPane] = useState('Job Details');
  return (
    <SinglePageJobDetail.Wrapper pb='1rem'>
      <Box className='wrapper' width={width}>
        {/* <Box
          border='1px solid #E1E1E1'
          borderRadius='7px'
          p='2rem 1rem'
          className='xs_hide sm_hide md_hide'
          // sx={{
          //   display: 'flex',
          //   justifyContent: 'center'
          // }}
        >
        <div className='d-flex justify-content-end'>
            <p className=''>
              {moment(
                formik?.values?.created_at || formik?.created_at || new Date()
              ).fromNow()}
            </p>
          </div>
          <div
            className='mt-4'
            style={{
              margin: '0 auto',

              display: 'flex',
              flexDirection: 'column',
              gap: '11px'
            }}
          >
            <p className='sub_title'>
              {formik?.values?.department || formik?.department}
            </p>
            <h4 className='page_title'>
              {formik?.values?.title || formik?.title}
            </h4>
            <div className='d-flex align-items-center gap-4'>
              <p className='sub_title'>
                <LocationOnIcon />{' '}
                {formik?.values?.job_type || formik?.job_type}
              </p>
              <p className='sub_title'>
                <LocationOnIcon />{' '}
                {`${formik?.values?.city || formik?.city}, ${
                  formik?.values?.state || formik?.state
                }`}
              </p>
              <p className='sub_title'>
                <Work className='me-2' size='2px' />
                {formik?.values?.employmenttype ||
                  formik?.employmenttype?.title}
              </p>
            </div>
          </div>
        </Box> */}
        <div
          className='w-full lg:h-[286px] border rounded-[7px] border-[#e1e1e1] p-[32px]'
          style={{
            boxShadow: ' 0px 7px 20px 0px rgba(40, 41, 61, 0.08)'
          }}
        >
          <div className='flex justify-between lg:flex-row flex-col gap-y-[18px]'>
            <div>
              <p className='lg:text-[28px] font-bold'>
                {' '}
                {formik?.values?.title || formik?.title}
              </p>
              <p className='text-[14px] text-[#545454] font-[400]'>
                {formik?.city && (
                  <span className='sub_title'>{`${formik?.city}, `}</span>
                )}

                {formik?.state && (
                  <span className='sub_title'>{formik?.state}</span>
                )}
              </p>
            </div>
          </div>

          <hr className=' border-[0.5px] w-full my-[26px] border-[#e1e1e1]' />

          <div className='flex gap-x-[41px] gap-y-[18px] flex-col lg:flex-row'>
            <div className='w-[289px]'>
              <p className='text-[14px] text-[#333E49] font-[400]'>
                Department
              </p>
              <p className='text-[16px] text-[#545454] font-[600]  leading-[22px]'>
                {formik?.values?.department || formik?.department}
              </p>
            </div>
            <div className='w-[289px]'>
              <p className='text-[14px] text-[#333E49] font-[400]'>
                Employment Type
              </p>
              <p className='text-[16px] text-[#545454] font-[600]  leading-[22px]'>
                {formik?.values?.employmenttype ||
                  formik?.employmenttype?.title}
              </p>
            </div>
            <div className='w-[289px]'>
              <p className='text-[14px] text-[#333E49] font-[400]'>Job Type</p>
              <p className='text-[16px] text-[#545454] font-[600] leading-[22px]'>
                {formik?.values?.job_type || _.capitalize(formik?.job_type)}
              </p>
            </div>
          </div>
          <div className='flex gap-x-[41px] gap-y-[18px] lg:mt-[27px] mt-[18px] flex-col lg:flex-row'>
            <div className='w-[289px]'>
              <p className='text-[14px] text-[#333E49] font-[400]'>
                Salary Range
              </p>
              {formik?.values?.fix_salary === true ||
              formik?.fix_salary === true ? (
                <p className='text-[16px] text-[#545454] font-[600]  leading-[22px]'>
                  ₦{' '}
                  {`${
                    formik?.values?.salary ||
                    new Intl.NumberFormat().format(Number(formik?.salary))
                  } ${
                    formik?.rate
                      ? formik?.rate
                      : '' || formik?.values?.rate
                      ? formik?.values?.rate
                      : ''
                  }`}
                </p>
              ) : (
                <p className='text-[16px] text-[#545454] font-[600]  leading-[22px]'>
                  ₦
                  {formik?.values?.min_salary ||
                    new Intl.NumberFormat().format(formik?.min_salary)}{' '}
                  - ₦
                  {formik?.values?.max_salary ||
                    new Intl.NumberFormat().format(formik?.max_salary) ||
                    '---'}{' '}
                  {formik?.rate ? formik?.rate : ''}
                </p>
              )}
            </div>
            <div className='w-[289px]'>
              <p className='text-[14px] text-[#333E49] font-[400]'>
                Experience Level
              </p>
              <p className='text-[16px] text-[#545454] font-[600]  leading-[22px]'>
                {formik?.values?.experience_level ||
                  formik?.experience_level?.title}
              </p>
            </div>
          </div>
        </div>
        <Box
          border='1px solid #E1E1E1'
          borderRadius='7px'
          bgcolor='#ffffff'
          p='1.5rem'
          m='1rem'
          className='lg_hide xl_hide'
        >
          <div>
            <CustomText fontSize='12px' fontWeight='400' color='#545454'>
              {formik?.values?.department || formik?.department}
            </CustomText>
            <CustomText fontSize='16px' fontWeight='600' color='#545454'>
              {formik?.values?.title || formik?.title}
            </CustomText>
            <div className='d-flex align-items-center gap-4'>
              <div className='d-flex align-items-center gap-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='10'
                  height='13'
                  viewBox='0 0 10 13'
                  fill='none'
                >
                  <path
                    d='M4.6 6.5C4.91625 6.5 5.18708 6.3873 5.41248 6.1619C5.63788 5.9365 5.75038 5.66587 5.75 5.35C5.75 5.03375 5.6373 4.76292 5.4119 4.53752C5.1865 4.31212 4.91587 4.19962 4.6 4.2C4.28375 4.2 4.01292 4.3127 3.78752 4.5381C3.56212 4.7635 3.44962 5.03413 3.45 5.35C3.45 5.66625 3.5627 5.93708 3.7881 6.16248C4.0135 6.38788 4.28413 6.50038 4.6 6.5ZM4.6 12.25C3.05708 10.9371 1.90478 9.7177 1.1431 8.59185C0.381417 7.466 0.000383333 6.42372 0 5.465C0 4.0275 0.462492 2.88229 1.38748 2.02937C2.31246 1.17646 3.3833 0.75 4.6 0.75C5.81708 0.75 6.88812 1.17646 7.8131 2.02937C8.73808 2.88229 9.20038 4.0275 9.2 5.465C9.2 6.42333 8.81897 7.46562 8.0569 8.59185C7.29483 9.71808 6.14253 10.9375 4.6 12.25Z'
                    fill='#878787'
                  />
                </svg>
                <CustomText fontSize='12px' fontWeight='400' color='#545454'>
                  {formik?.values?.city || formik?.city},{' '}
                  {formik?.values?.state || formik?.state}, Nigeria
                </CustomText>
              </div>
              <div className='d-flex align-items-center gap-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='10'
                  height='13'
                  viewBox='0 0 10 13'
                  fill='none'
                >
                  <path
                    d='M4.6 6.5C4.91625 6.5 5.18708 6.3873 5.41248 6.1619C5.63788 5.9365 5.75038 5.66587 5.75 5.35C5.75 5.03375 5.6373 4.76292 5.4119 4.53752C5.1865 4.31212 4.91587 4.19962 4.6 4.2C4.28375 4.2 4.01292 4.3127 3.78752 4.5381C3.56212 4.7635 3.44962 5.03413 3.45 5.35C3.45 5.66625 3.5627 5.93708 3.7881 6.16248C4.0135 6.38788 4.28413 6.50038 4.6 6.5ZM4.6 12.25C3.05708 10.9371 1.90478 9.7177 1.1431 8.59185C0.381417 7.466 0.000383333 6.42372 0 5.465C0 4.0275 0.462492 2.88229 1.38748 2.02937C2.31246 1.17646 3.3833 0.75 4.6 0.75C5.81708 0.75 6.88812 1.17646 7.8131 2.02937C8.73808 2.88229 9.20038 4.0275 9.2 5.465C9.2 6.42333 8.81897 7.46562 8.0569 8.59185C7.29483 9.71808 6.14253 10.9375 4.6 12.25Z'
                    fill='#878787'
                  />
                </svg>
                <CustomText fontSize='12px' fontWeight='400' color='#545454'>
                  {formik?.values?.employmenttype ||
                    formik?.employmenttype?.title}
                </CustomText>
              </div>
              <div className='d-flex align-items-center gap-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='10'
                  height='13'
                  viewBox='0 0 10 13'
                  fill='none'
                >
                  <path
                    d='M4.6 6.5C4.91625 6.5 5.18708 6.3873 5.41248 6.1619C5.63788 5.9365 5.75038 5.66587 5.75 5.35C5.75 5.03375 5.6373 4.76292 5.4119 4.53752C5.1865 4.31212 4.91587 4.19962 4.6 4.2C4.28375 4.2 4.01292 4.3127 3.78752 4.5381C3.56212 4.7635 3.44962 5.03413 3.45 5.35C3.45 5.66625 3.5627 5.93708 3.7881 6.16248C4.0135 6.38788 4.28413 6.50038 4.6 6.5ZM4.6 12.25C3.05708 10.9371 1.90478 9.7177 1.1431 8.59185C0.381417 7.466 0.000383333 6.42372 0 5.465C0 4.0275 0.462492 2.88229 1.38748 2.02937C2.31246 1.17646 3.3833 0.75 4.6 0.75C5.81708 0.75 6.88812 1.17646 7.8131 2.02937C8.73808 2.88229 9.20038 4.0275 9.2 5.465C9.2 6.42333 8.81897 7.46562 8.0569 8.59185C7.29483 9.71808 6.14253 10.9375 4.6 12.25Z'
                    fill='#878787'
                  />
                </svg>
                <CustomText fontSize='12px' fontWeight='400' color='#545454'>
                  {formik?.values?.job_type || formik?.job_type}
                </CustomText>
              </div>
            </div>
          </div>
        </Box>
        {/* {!isPublic && ( */}
        <div className='my-4'>
          <TabLeftButton
            isActive={activeJobPane}
            onClick={() => setActiveJobPane('Job Details')}
          >
            Job Details
          </TabLeftButton>
          <TabRightButton
            isActive={activeJobPane}
            onClick={() => setActiveJobPane('Application Form')}
          >
            Application Form
          </TabRightButton>
        </div>
        {/* )} */}
        <div className='bg_white rounded mt-4 p-3'>
          {activeJobPane === 'Job Details' ? (
            <JobDetail
              data={data}
              formik={formik}
              isPublic={isPublic}
              setActiveJobPane={setActiveJobPane}
              hideApplicationButton={hideApplicationButton}
            />
          ) : (
            <PreviewApplication
              values={formik?.values || formik}
              applicationForm={applicationForm}
              isApplying={true}
              setApplicationForm={setApplicationForm}
            />
          )}
        </div>
      </Box>
    </SinglePageJobDetail.Wrapper>
  );
};

export default SinglePageJobDetail;

SinglePageJobDetail.Wrapper = styled(Box)`
  .tags {
    font-weight: 400;
    font-size: 14px;
    line-height: 109.36%;
    color: #878787;

    .dot {
      width: 5.14px;
      height: 5.14px;
      font-size: 50px;
    }
  }

  .bg_white {
    background: #ffffff;

    @media (max-width: 700px) {
      background: #fafafa;
    }
  }

  .wrapper {
    border-radius: 20px;
    margin: 0 auto 2rem;
    padding: 1.3rem;

    @media (max-width: 700px) {
      padding: 0;
      border: none;
    }
    width: ${(props) => props.width && props.width};
  }

  .back {
    font-weight: 600;
    font-size: 14px;
    line-height: 128.86%;
    color: #545454;
  }

  .title {
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    color: #545454;
  }

  .page_title {
    font-weight: 600;
    font-size: 32px;
    line-height: 35px;
    color: #545454;
  }

  .sub_title {
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: #545454;
  }

  ul {
    list-style-type: disc;
    margin-left: 1rem;
  }

  .salary_text {
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    color: #545454;
  }

  .application_deadline {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #545454;
  }

  .view_more {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #2898a4;
  }
`;

const TabLeftButton = styled.button`
  border-bottom: ${(props) =>
    props.isActive === 'Job Details' ? '2px solid #2898a4' : 'none'};
  border-radius: ${(props) =>
    props.isActive === 'Job Details' ? '7px 0px 0px 0px' : '7px 0px 0px 0px'};
  font-family: 'Black Sans';
  background: ${(props) =>
    props.isActive === 'Job Details' ? '#d6f2f5' : '#ffffff'};
  color: ${(props) =>
    props.isActive === 'Job Details' ? '#2898a4' : '#545454'};
  padding: 1rem 2rem;
  font-style: normal;
  text-align: center;
  line-height: 11px;
  font-weight: 600;
  font-size: 14px;
`;

const TabRightButton = styled.button`
  border-bottom: ${(props) =>
    props.isActive === 'Application Form' ? '2px solid #2898a4' : 'none'};
  border-radius: ${(props) =>
    props.isActive === 'Application Form'
      ? '0px 7px 0px 0px'
      : '0px 7px 0px 0px'};
  font-family: 'Black Sans';
  background: ${(props) =>
    props.isActive === 'Application Form' ? '#d6f2f5' : '#ffffff'};
  color: ${(props) =>
    props.isActive === 'Application Form' ? '#2898a4' : '#545454'};
  padding: 1rem 2rem;
  font-style: normal;
  text-align: center;
  line-height: 11px;
  font-weight: 600;
  font-size: 14px;
`;
