import { useQuery } from 'react-query';
import { getMethod } from 'hooks/queries/getHook';
import React from 'react';
import moment from 'moment';

export const useGetShiftRota = ({ params, apiUrl }) => {
  const [isPublished, setIsPublished] = React.useState(false);
  const [shiftHeader, setShiftHeader] = React.useState(['Employee']);
  const { data, isLoading, refetch } = useQuery(
    [
      'get-shift',
      {
        route: `${apiUrl}`,
        params: params
      }
    ],
    {
      queryFn: getMethod,
      // enabled: isSuccess && userPermission.is_owner,
      onSuccess: (data) => {
        setIsPublished(data?.published);
        setShiftHeader([
          'Employee',
          ...data?.data[0]?.shifts?.map((item) =>
            moment(item?.date).format('ddd DD')
          )
        ]);
      }
    }
  );

  return { data, isLoading, refetch, isPublished, shiftHeader };
};

export const useShiftConfig = () => {
  const [shiftConfigId, setShiftConfigId] = React.useState();
  const [shiftConfig, setShiftConfig] = React.useState({
    time_format: '12',
    week_start: 'monday'
  });

  const { isLoading: configLoading } = useQuery(
    ['get-shift-config', { route: '/shift/config/' }],
    getMethod,
    {
      onSuccess: (data) => {
        setShiftConfigId(data.id);
        setShiftConfig({
          ...shiftConfig,
          time_format: data.time_format,
          week_start: data.week_start
        });
      }
    }
  );

  return { shiftConfig, shiftConfigId, configLoading, setShiftConfig };
};
