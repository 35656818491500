import { useEffect, useRef } from 'react';
import axiosInstance from 'api/axios.config';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const whiteListedRoute = [
  '/dashboard',
  '/hr?type=all-employees&tab=all-employee-home'
];
const restrictionStatus = 402;
const usePlanRestrictionInterceptor = ({ toggle, opened }) => {
  const location = useLocation();
  const isHandlingRestriction = useRef(false);

  useEffect(() => {
    // Add the interceptor
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      // disable all plan modal on dashboard

      (error) => {
        const responseUrl = error.request.responseURL;
        if (!responseUrl) {
          return Promise.reject(error);
        }
        const responseErrorStatus = error?.response?.status;

        const urlObj = new URL(responseUrl);
        const xReferer = urlObj.searchParams.get('x-referer');
        const fullPathname = location.pathname + location.search;
        const initiator = xReferer ?? fullPathname;
        // console.log(
        //   {
        //     xReferer,
        //     isRestrictionError: responseErrrorStatus === restrictionStatus,
        //     responseErrrorStatus,
        //     restrictionStatus,
        //     responseUrl
        //   },
        //   'xReference'
        // );
        if (
          responseErrorStatus === restrictionStatus &&
          !whiteListedRoute.some((route) => initiator.includes(route))
        ) {
          // shift api and not shift route
          const isNotShiftRoute =
            !location.pathname.includes('shift') &&
            responseUrl.includes('shift');
          // time and attendance api and not time and attendance route
          const isNotAttendanceRoute =
            !location.pathname.includes('time-and-attendance') &&
            responseUrl.includes('attendance');

          // if shift api and not shift route or time and attendance api and not time and attendance route
          if (isNotShiftRoute || isNotAttendanceRoute) {
            return Promise.reject(error);
          } else if (!isHandlingRestriction.current) {
            isHandlingRestriction.current = true;
            toggle();
            setTimeout(() => {
              isHandlingRestriction.current = false;
            }, 1000);
          }
        }
        return Promise.reject(error);
      }
    );

    // Cleanup function to remove the interceptor when the component unmounts
    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [toggle, opened, location]);

  useEffect(() => {
    if (!opened) {
      isHandlingRestriction.current = false;
    }
  }, [opened]);

  return null;
};

export default usePlanRestrictionInterceptor;
