import { Search } from '@material-ui/icons';
import Select from 'react-select';
import { FlexBox } from '../../common/Reusables';
import { TextField } from '@mui/material';

const AssignFilter = ({
  depData,
  isDepartmentLoading,
  searchRef,
  debouceSearch,
  setDepartmentFilter,
  allowDepartment,
  selectedDepartment,
  setSearchFilter
}) => {
  return (
    <FlexBox gap='10px'>
      <div
        style={{
          flex: 2
        }}
      >
        <TextField
          className='search_input'
          size='small'
          fullWidth
          id=''
          label=''
          ref={searchRef}
          // value={!!searchFilter ? ""}
          onChange={(e) => {
            setDepartmentFilter(null);
            debouceSearch(e.target.value);
          }}
          InputProps={{
            startAdornment: <Search position='start' />
          }}
        />
      </div>
      {allowDepartment && (
        <div
          style={{
            flex: 1
          }}
        >
          <Select
            className='select_input'
            placeholder='Department'
            value={selectedDepartment}
            isLoading={isDepartmentLoading}
            isSearchable={false}
            isClearable
            onChange={(value) => {
              setSearchFilter('');
              if (!!searchRef?.current?.target?.value) {
                searchRef.current.target.value = '';
              }
              setDepartmentFilter(value);
            }}
            options={depData}
          />
        </div>
      )}
    </FlexBox>
  );
};

export default AssignFilter;
