import _ from 'lodash';

export const asset_major_types = [
  // { label: 'All', value: 'all' },
  { label: 'Assets', value: false },
  { label: 'Vehicles', value: true }
];

export const statusColors = {
  out_for_repair: { bg: '#FCE2AF', c: '#946405' },
  missing: { bg: '#BCEFFF', c: '#37A5C9' },
  sold: { bg: '#DCFFD0', c: '#545454' },
  unassigned: { bg: '#F2F2F2', c: '#878787' },
  condemned: { bg: '#FFE5E5', c: '#FF6666' },
  discarded: { bg: '#C8D7FF', c: '#7C808B' },
  active: { bg: '#EAF8FA', c: '#2898A4' }
};

const averageSpace = 6.5 / 3;
let spacingSet = [...new Array(7).fill(averageSpace).concat([0.5])];

spacingSet[1] = 1;
spacingSet[3] = 1.2;
spacingSet[4] = 1.3;
spacingSet[6] = 1.5;

export const spacing = spacingSet;

export const formatAssetList = (assetList = []) => {
  const dataSourceRaw =
    assetList?.map((el) => ({
      ...el,
      assigne_to: el.assignee,
      department: el?.assignee?.department,
      name: el?.name?.toLowerCase()
    })) || [];

  const dataSource = _.sortBy(dataSourceRaw, ['name']);

  return dataSource;
};
