import AssetDocumentModal from 'Assets/Modals/AssetDocument/AssetDocumentModal';
import {
  useCreateAsset,
  useFetchAsset,
  useFetchAssetTypes2
} from 'Assets/queries';
import { CustomButton as Button } from 'components/Button';
import CircularLoader from 'components/CircularLoader';
import { CustomSelect } from 'components/Inputs';
import Loader from 'components/Loader/Loader';
import Group from 'components/StyledComponents/ButtonGroup';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import useDisclosure from 'hooks/useDisclosure';
import qs from 'query-string';
import { useState } from 'react';
import styled from 'styled-components';
import { formatDate } from 'utilities/formHelpers';
import AssetAddSuccessModal from '../Modals/AssetTypeAddSuccessModal';
import AddImage from './AddImage';
import AdditionalInformation from './AdditionalInfo';
import AssetForm from './AssetForm';
import AssignEmployee from './AssignEmployee';
import FormSectionTitle from './FormSectionTitle';
import Header from './Header';
import PurchaseInformation from './PurchaseInformation';
import VehicleForm from './VehicleForm';
import {
  assetInitial,
  assetSchema,
  assetType,
  vechicleInitial,
  vehicleSchema
} from './data';
import { getMethod } from 'hooks/queries/getHook';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const AddAsset = () => {
  const [openedSuccessModal, { toggle: toggleSuccessModal }] = useDisclosure();
  const [openedDocumentModal, { toggle: toggleDocumentModal }] =
    useDisclosure();
  const [assetId, setAssetId] = useState(null);
  const [formType, setFormType] = useState('asset');
  const [vehicleName, setVehicleName] = useState('');
  const { push, goBack } = useHistory();

  const { data: assetTypes } = useFetchAssetTypes2({
    search: 'vehicle'
  });

  const editId = qs.parse(window.location.search)?.id;

  const assetQuery = useFetchAsset(
    { assetId: editId },
    {
      enabled: Boolean(editId),
      onSuccess(res) {
        if (res?.asset_type?.name === 'Vehicle') {
          setFormType('vehicle');
        }
      }
    }
  );

  const { data: regions } = useQuery(
    ['get-branch', { route: '/regions/all/', params: {} }],
    getMethod
  );

  const locations =
    regions?.map((type) => ({
      label: type.name,
      value: type.id,
      ...type
    })) || [];

  const vehicleId = assetTypes?.find((el) =>
    ['Vehicles', 'Vehicle'].includes(el.name)
  )?.id;

  const mutation = useCreateAsset({
    editId,
    onSuccess(res) {
      setAssetId(res?.asset_Id ?? res?.id);
      toggleSuccessModal();
      goBack();
    },
    onError(err) {}
  });

  if (assetQuery.isLoading) {
    return <Loader />;
  }

  const handleSubmit = (values) => {
    const assetPayload = {
      ...values,
      purchase_date: formatDate(values?.purchase_date),
      name: formType === 'vehicle' ? vehicleName : values.name,
      date_assigned: formatDate(values.date_assigned),
      cost: values.cost ? parseInt(values.cost) : null
    };
    if (values.asset_condition === '') {
      delete assetPayload.asset_condition;
    }
    if (formType === 'vehicle') {
      assetPayload.asset_type = vehicleId;
      assetPayload.year = values.year
        ? parseInt(dayjs(values.year).format('YYYY'))
        : null;
    }

    const payload = structuredClone(assetPayload);

    if (formType === 'vehicle') {
      payload.vehicle = structuredClone(assetPayload);
    }

    mutation.mutate(payload);
  };

  return (
    <AddAssetStyled>
      <Header />
      <Formik
        enableReinitialize
        validateOnBlur
        initialValues={
          formType === 'vehicle'
            ? vechicleInitial(formType, assetQuery.data, Boolean(editId))
            : assetInitial(formType, assetQuery?.data, Boolean(editId))
        }
        validationSchema={formType === 'vehicle' ? vehicleSchema : assetSchema}
      >
        {({ values, handleBlur, handleChange, errors }) => {
          return (
            <>
              <div className='form_block'>
                <FormSectionTitle>Asset Information</FormSectionTitle>
                <CustomSelect
                  label='Select Asset or Vehicle'
                  value={formType}
                  name='assetType'
                  size='small'
                  onChange={(e) => {
                    setFormType(e.target.value);

                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  options={assetType}
                  error={!!errors.assetType}
                  info={errors.assetType}
                />
                {values.assetType !== 'vehicle' ? (
                  <AssetForm locations={locations} />
                ) : null}
                {values.assetType !== 'vehicle' ? (
                  <AdditionalInformation />
                ) : null}

                {values.assetType === 'vehicle' ? (
                  <VehicleForm
                    vehicleName={vehicleName}
                    setVehicleName={setVehicleName}
                    locations={locations}
                    editMode={Boolean(editId)}
                  />
                ) : null}
                <PurchaseInformation />
                {!Boolean(editId) && (
                  <AddImage
                    handleChange
                    data={assetQuery?.data?.image}
                    editMode={Boolean(editId)}
                  />
                )}
                {/* <AddDocument /> */}
                <AssignEmployee assignee={assetQuery?.data?.assignee} />
              </div>
              <Group fullWidth className='action_block'>
                <Button
                  bold
                  fullWidth
                  variant='outlined'
                  className='outlined offwhite'
                  onClick={goBack}
                >
                  Cancel
                </Button>
                <Button
                  bold
                  disabled={mutation.isLoading}
                  onClick={() => handleSubmit(values)}
                  fullWidth
                  variant='contained'
                >
                  <CircularLoader size={15} show={mutation.isLoading} />
                  {editId ? 'Edit Asset' : 'Add Asset'}
                </Button>
              </Group>
            </>
          );
        }}
      </Formik>
      {openedSuccessModal && (
        <AssetAddSuccessModal
          open={openedSuccessModal}
          toggle={toggleSuccessModal}
          toggleAddDoc={toggleDocumentModal}
        />
      )}

      {openedDocumentModal ? (
        <AssetDocumentModal
          open={openedDocumentModal}
          toggle={toggleDocumentModal}
          assetId={assetId}
          onSuccess={() => {
            push('/assets?tab=asset-list&view=list');
          }}
        />
      ) : null}
    </AddAssetStyled>
  );
};

export default AddAsset;

const AddAssetStyled = styled.div`
  width: min(100%, 600px);
  margin-inline: auto;
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  .header_block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .typos {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      margin-bottom: 0.5rem;
    }
  }

  .form_block {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .action_block {
    margin-block: 1rem;
  }
`;
