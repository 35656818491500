import { progressEventHandler } from 'utilities/utils';
import axios from './axios.config';
import getBaseDomainURL from './base-domain-url';

export function getAsset(params = {}) {
  return axios
    .get(`${getBaseDomainURL()}/asset-management/assets/`, { params })
    .then(({ data }) => data);
}

export function paginateAsset(params) {
  const { newPage } = params;
  return axios
    .get(`${getBaseDomainURL()}/asset-management/assets/?page=${newPage}`)
    .then(({ data }) => data);
}

export function getAssetStatus(status) {
  return axios
    .get(`${getBaseDomainURL()}/asset-management/assets/?status=${status}`)
    .then(({ data }) => data);
}

export function assetFilter(params) {
  const { newPage, status, asset_type, search, setLoadingHandler } = params;
  const url =
    status && asset_type
      ? `status=${status}&asset_type=${asset_type}`
      : status
      ? `status=${status}`
      : asset_type
      ? `asset_type=${asset_type}`
      : null;
  return axios
    .get(
      `${getBaseDomainURL()}/asset-management/assets/?page=${newPage}&search=${
        search || ''
      }&${url}`
    )
    .then(({ data }) => {
      setLoadingHandler(false);
      return data;
    })
    .catch((error) => {
      setLoadingHandler(false);
      throw error;
    });
}

export function getAssetCategory(id) {
  return axios
    .get(`${getBaseDomainURL()}/asset-management/assets/?category=${id}`)
    .then(({ data }) => data);
}

export function getSearchAsset(search) {
  return axios
    .get(`${getBaseDomainURL()}/asset-management/assets/?search=${search}`)
    .then(({ data }) => data);
}

export function getOneAsset({ queryKey }) {
  const { assetId } = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/asset-management/assets/${assetId}`)
    .then(({ data }) => data);
}

export function addAsset(payload) {
  return axios
    .post(`${getBaseDomainURL()}/asset-management/assets/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function editAsset(payload, id) {
  return axios
    .put(`${getBaseDomainURL()}/asset-management/assets/${id}/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addCategories(payload) {
  return axios
    .post(`${getBaseDomainURL()}/asset-management/categories/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getCategories() {
  return axios
    .get(`${getBaseDomainURL()}/asset-management/categories/`)
    .then(({ data }) => data);
}

// not used
export function getSubCategories({ queryKey }) {
  if (queryKey) {
    const { pageSize, page } = queryKey[1];
    return axios
      .get(
        `${getBaseDomainURL()}/asset-management/subcategories/?page_size=${
          pageSize || ''
        }&page=${page || ''}`
      )
      .then(({ data }) => data);
  }
}
//

export function getAssetTypes(url) {
  const URL = url ? url : `${getBaseDomainURL()}/asset-management/asset_types/`;
  return axios
    .get(URL)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getAssetTypeList() {
  return axios
    .get(`${getBaseDomainURL()}/asset-management/asset_types/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getAddAssetTypes(payload = {}) {
  return axios
    .get(`${getBaseDomainURL()}/asset-management/asset_types/`, {
      params: { ...payload }
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function getAllAssetTypes(payload = {}) {
  return axios
    .get(
      `${getBaseDomainURL()}/asset-management/asset_types/all_asset_types/`,
      {
        params: { ...payload }
      }
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function getAllAssetTypes2(payload = {}) {
  return axios
    .get(`${getBaseDomainURL()}/asset-management/asset_types/all/`, {
      params: { ...payload }
    })
    .then(({ data }) => data?.data)
    .catch((error) => {
      throw error;
    });
}

export function getAssetTypeStats({ queryKey }) {
  if (queryKey) {
    const { pageSize, page } = queryKey[1];
    return axios
      .get(
        `${getBaseDomainURL()}/asset-management/dashboard/asset_type_stats/?page_size=${
          pageSize || ''
        }&page=${page || ''}`
      )
      .then(({ data }) => data);
  }
}

export function getSubCategoriesPaginated(page) {
  return axios
    .get(`${getBaseDomainURL()}/asset-management/subcategories/?page=${page}`)
    .then(({ data }) => data);
}

export function searchAssetTypeList(search) {
  return axios
    .get(`${getBaseDomainURL()}/asset-management/asset_types/?search=${search}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addAssetType(payload) {
  return axios
    .post(`${getBaseDomainURL()}/asset-management/asset_types/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function addIssueType(payload) {
  return axios
    .post(`${getBaseDomainURL()}/asset-management/issue-type/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function editIssueType(payload, id) {
  return axios
    .patch(`${getBaseDomainURL()}/asset-management/issue-type/${id}/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addAssignee({ id, payload }) {
  return axios
    .post(
      `${getBaseDomainURL()}/asset-management/assets/${id}/add_assignee/`,
      payload
    )
    .then(({ data }) => data);
}

export function removeAssignee({ payload, id }) {
  return axios
    .post(
      `${getBaseDomainURL()}/asset-management/assets/${id}/remove_assignee/`,
      payload
    )
    .then(({ data }) => data);
}

export function getAssignees({ queryKey }) {
  const { assetId } = queryKey[1];
  return axios
    .get(
      `${getBaseDomainURL()}/asset-management/assets/${assetId}/assignee_history/`
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function removeAsset(payload) {
  return axios
    .patch(
      `${getBaseDomainURL()}/asset-management/assets/bulk_delete/`,
      payload
    )
    .then(({ data }) => data);
}

export function addNewDocument(payload) {
  return axios
    .post(`${getBaseDomainURL()}/asset-management/attachments/add/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addStatutoryDocument(values) {
  const payload = values.others ? values.formData : values;
  const url = `${getBaseDomainURL()}/asset-management/attachments${
    values.others ? '/add/' : '/'
  }`;
  return axios
    .post(url, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addAttachment(payload, progressHandler) {
  return axios
    .post(`${getBaseDomainURL()}/asset-management/attachments/`, payload, {
      onUploadProgress: progressEventHandler(progressHandler)
    })
    .then(({ data }) => data);
}

export function removeDocument(id) {
  return axios
    .delete(`${getBaseDomainURL()}/asset-management/attachments/${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function recentlyAddedAsset() {
  return axios
    .get(
      `${getBaseDomainURL()}/asset-management/dashboard/recently_added_assets/`
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function recentlyAddedAssetType() {
  return axios
    .get(
      `${getBaseDomainURL()}/asset-management/asset_types/recently_added_asset_types/`
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getAssetBreakdown() {
  return axios
    .get(
      `${getBaseDomainURL()}/asset-management/dashboard/asset_status_breakdown/`
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getAssetFlow() {
  return axios
    .get(`${getBaseDomainURL()}/asset-management/dashboard/asset_flow/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getCategoryStats(page) {
  return axios
    .get(
      `${getBaseDomainURL()}/asset-management/dashboard/category_stats/?page=${page}`
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getSubcategoryStats(id) {
  return axios
    .get(
      `${getBaseDomainURL()}/asset-management/dashboard/subcategory_stats/?category=${id}`
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getCategoryList(page = 1) {
  return axios
    .get(
      `${getBaseDomainURL()}/asset-management/category_subcategories/?page=${page}`
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function searchCategoryList(search, page = 1) {
  return axios
    .get(
      `${getBaseDomainURL()}/asset-management/category_subcategories/?search=${search}&page=${page}`
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function removeCategory(id) {
  return axios
    .delete(`${getBaseDomainURL()}/asset-management/categories/${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function editCategory(payload, id) {
  return axios
    .put(`${getBaseDomainURL()}/asset-management/categories/${id}/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function removeSubcategory(id) {
  return axios
    .delete(`${getBaseDomainURL()}/asset-management/asset_types/${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function editAssetType(payload, id) {
  return axios
    .put(`${getBaseDomainURL()}/asset-management/asset_types/${id}/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function uploadAssetImage(payload) {
  const { formDataUpload, assetId } = payload;
  return axios
    .patch(
      `${getBaseDomainURL()}/asset-management/assets/${assetId}/upload_image/`,
      formDataUpload
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function removeAssetImage(payload) {
  return axios
    .patch(
      `${getBaseDomainURL()}/asset-management/assets/${payload}/remove_image/`
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getAssetType(param) {
  if (param) {
    return axios
      .get(`${getBaseDomainURL()}/asset-management/asset_types/?page=${param}`)
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  }
}
export function getAssetReminders(params) {
  return axios
    .get(`${getBaseDomainURL()}/asset-management/reminders/reminders_filter/`, {
      params
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function getAssetServiceAndRepair(params) {
  return axios
    .get(`${getBaseDomainURL()}/asset-management/maintenance/`, { params })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function getAssetMaintenance({ queryKey }) {
  const query = structuredClone(queryKey[1]);
  const { params, assetId } = getPk(query, 'assetId');
  return axios
    .get(
      `${getBaseDomainURL()}/asset-management/assets/${assetId}/get_asset_maintenance/`,
      { params }
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addNewMaintenance(payload) {
  return axios
    .post(`${getBaseDomainURL()}/asset-management/maintenance/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function updateMaintenance(data) {
  const { editId, payload } = getPk(data, 'editId');
  return axios
    .patch(
      `${getBaseDomainURL()}/asset-management/maintenance/${editId}/`,
      payload
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getMakes(params) {
  return axios
    .get(`${getBaseDomainURL()}/make/`, { params })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getMake({ id, type }) {
  return axios
    .get(`${getBaseDomainURL()}/make/${id}/`, { params: { type } })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getMakeModel({ id, type }) {
  return axios
    .get(`${getBaseDomainURL()}/make/${id}/models/`, { params: { type } })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function createReminder(payload) {
  return axios
    .post(`${getBaseDomainURL()}/asset-management/reminders/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function createRenewal(payload, id) {
  return axios
    .post(`${getBaseDomainURL()}/asset-management/reminders/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function updateRenewal(payload, id) {
  return axios
    .post(`${getBaseDomainURL()}/asset-management/reminders/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getIssueTypes({ queryKey }) {
  const params = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/asset-management/issue-type/`, { params })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function getAssetReportedIssues(ref) {
  const { queryKey } = ref;
  const query = queryKey[1];

  const { params, asset_pk } = getPk(query, 'asset_pk');

  return axios
    .get(`${getBaseDomainURL()}/asset-management/assets/${asset_pk}/issues/`, {
      params
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function getMaintenances(params) {
  return axios
    .get(`${getBaseDomainURL()}/asset-management/maintenance/`, { params })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function getMaintenance({ queryKey }) {
  const query = queryKey[1];
  const { asset_pk, params } = getPk(query, 'asset_pk');
  return axios
    .get(`${getBaseDomainURL()}/asset-management/maintenance/${asset_pk}/`, {
      params
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function removeMaintenance({ id }) {
  return axios
    .delete(`${getBaseDomainURL()}/asset-management/maintenance/${id}/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function removeReminder(id) {
  return axios
    .delete(`${getBaseDomainURL()}/asset-management/reminders/${id}/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
const getPk = (payload, pk) => {
  const newPayload = structuredClone(payload);
  const pkValue = payload[pk];
  delete newPayload[pk];
  return { [pk]: pkValue, payload: newPayload, params: newPayload };
};
export function createIssueReport(data) {
  const { payload, asset_pk } = getPk(data, 'asset_pk');
  return axios
    .post(
      `${getBaseDomainURL()}/asset-management/assets/${asset_pk}/issues/`,
      payload
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getReportedIssues({ queryKey }) {
  const params = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/asset-management/issues-list/`, {
      params
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getAssetAttachments({ queryKey }) {
  const query = queryKey[1];
  const { asset_pk, params } = getPk(query, 'asset_pk');
  return axios
    .get(
      `${getBaseDomainURL()}/asset-management/assets/${asset_pk}/get_asset_attachments/`,
      {
        params
      }
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function removeAssetType({ id }) {
  return axios
    .delete(`${getBaseDomainURL()}/asset-management/asset_types/${id}/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getVehicleTypesList(query) {
  const params = query?.queryKey[1];
  return axios
    .get(
      `${getBaseDomainURL()}/asset-management/asset_types/vehicle_type_list/`,
      { params }
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function getVehicleList(query) {
  const params = query?.queryKey[1];
  return axios
    .get(
      `${getBaseDomainURL()}/asset-management/asset_types/vehicle_type_details/`,
      { params }
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getDocumentTypes({ queryKey }) {
  const params = queryKey[1];
  return axios
    .get(
      `${getBaseDomainURL()}/asset-management/assets/list_of_document_types/`,
      { params }
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function createDocumentType(data) {
  return axios
    .post(
      `${getBaseDomainURL()}/asset-management/assets/create_document/`,
      data
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function updateDocumentType(data) {
  const { editId, payload } = getPk(data, 'editId');
  return axios
    .put(
      `${getBaseDomainURL()}/asset-management/assets/${editId}/edit_document_type/`,
      payload
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function removeDocumentType(data) {
  return axios
    .delete(
      `${getBaseDomainURL()}/asset-management/assets/${
        data?.id
      }/delete_document_type/`,
      data
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function importAssets(payload) {
  return axios
    .post(`${getBaseDomainURL()}/asset-management/assets/bulk_import/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
