import { useState } from 'react';
import {
  useGetShiftRota,
  useShiftConfig
} from 'ShiftManagement/components/useGetShiftRota';
import moment from 'moment';
import { noop } from 'lodash';

const ShiftProvider = ({ render = noop, children }) => {
  const { shiftConfig } = useShiftConfig();

  const [params, setParams] = useState({
    start_date: moment()
      .day(shiftConfig.week_start.toLowerCase())
      .format('YYYY-MM-DD'),
    end_date: moment()
      .day(shiftConfig.week_start.toLowerCase())
      .add(6, 'days')
      .format('YYYY-MM-DD')
  });

  const {
    data: shiftData,
    isLoading,
    isPublished,
    refetch,
    shiftHeader
  } = useGetShiftRota({
    apiUrl: '/shift/',
    params: params
  });
  return typeof children === 'function' ? (
    <>
      {children({
        shiftData,
        isLoading,
        isPublished,
        refetch,
        shiftHeader,
        setParams,
        params
      })}
    </>
  ) : (
    <>{children}</>
  );
};

export default ShiftProvider;
