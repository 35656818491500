import { Grid, Typography } from '@mui/material';
import { Children, useContext, useState } from 'react';
import { isFunc } from 'utilities/utils';
import LoadingRow from './LoadingRow';
import { TableContext } from './Table';

const TableBody = ({ marginTop = '1.8rem', ...props }) => {
  return (
    <div
      style={{
        marginTop: marginTop,
        backgroundOpacity: 0.2,
        overflow: 'hidden',
        border:
          props.seperate || props.shy
            ? '0px solid transparent'
            : '1px solid #E1E1E1'
      }}
    >
      {props.children}
    </div>
  );
};

export default TableBody;

export const TableRow = ({
  rowClick = null,
  rowData,
  cellStyle,
  rowIndex,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const context = useContext(TableContext);

  const spacing =
    context?.spacing ??
    props.spacing ??
    Array(props.components.length).fill(12 / props.components.length);

  const bordered = context?.bordered;
  return (
    <Grid
      container
      alignItems={bordered ? 'stretch' : 'center'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(event) => {
        if (isFunc(rowClick)) {
          rowClick(event, rowData);
        }
      }}
      sx={[
        rowClick && { cursor: 'pointer' },
        {
          backgroundColor: rowClick && isHovered ? '#FAFAFA' : 'inherit',
          py: bordered ? 0 : 1.5,
          px: bordered ? 0 : 2,
          marginBottom: props.seperate
            ? props.seperate === true
              ? '.3rem !important'
              : props?.seperate
            : '0 !important',
          border: props.seperate ? '1px solid #E1E1E1' : '',
          borderTop: props.seperate ? '0px solid #E1E1E1' : '',
          borderBottom: !props.isLast ? '1px solid #E1E1E1' : '',
          borderBottomLeftRadius: props.seperate && props.isLast ? 12 : '',
          borderBottomRightRadius: props.seperate && props.isLast ? 12 : ''
        }
      ]}
    >
      {props.components.map((item, index) => {
        const indexKey = context?.column
          ? context?.column[index]?.dataIndex
          : '';

        return (
          <Grid
            item
            xs={spacing[index]}
            key={index}
            sx={[
              item?.itemProps?.sx,
              {
                ...item?.itemProps?.sx,
                borderLeft:
                  index > 0 && bordered ? '1px solid #A8A8A8' : 'none',
                pl: index === 0 ? 2 : '.8rem',
                py: bordered ? 1.5 : 0
              },
              cellStyle &&
                cellStyle(
                  indexKey
                    ? rowData[indexKey]
                    : item.component?.props?.children,
                  {
                    index,
                    component: item.component,
                    rowIndex
                  }
                )
            ]}
            {...item.itemProps}
          >
            {item.component}
          </Grid>
        );
      })}
    </Grid>
  );
};

export const TableBodyV2 = ({
  renderRow,
  isError,
  isLoading,
  data: rawData,
  shy,
  seperate = false,
  rowClick = null,
  cellStyle,
  ...props
}) => {
  const context = useContext(TableContext);

  const data = context?.data ?? rawData;

  if (isError) {
    return (
      <TableBody>
        <Center>
          <Typography variant='body2' color='error' sx={{ maxWidth: 600 }}>
            {props.error ??
              'something went wrong please refresh and if the problem persists contact support'}
          </Typography>
        </Center>
      </TableBody>
    );
  }
  if (isLoading) {
    return (
      <TableBody>
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
      </TableBody>
    );
  }

  if (data?.length < 1) {
    return (
      <TableBody>
        <Center>
          <Typography variant='body2' sx={{ maxWidth: 600 }}>
            {props.emptyMessage ?? 'No data found'}
          </Typography>
        </Center>
      </TableBody>
    );
  }

  return (
    <TableBody shy={shy} marginTop={props?.marginTop} seperate={seperate}>
      {data.map(
        (
          row,
          index // going through each rows
        ) => {
          const components = Children.toArray(
            renderRow(row, index)?.props?.children
          );

          const mapedComp = components?.map((column) => ({
            component: column
          }));
          return (
            <TableRow
              rowClick={rowClick}
              seperate={seperate}
              key={index}
              rowIndex={index}
              rowData={row}
              isLast={index === data?.length - 1}
              components={mapedComp}
              cellStyle={cellStyle}
              {...props}
            />
          );
        }
      )}
    </TableBody>
  );
};

const Center = (props) => {
  return (
    <Grid
      container
      alignItems={'center'}
      height={100}
      justifyContent={'center'}
    >
      {props.children}
    </Grid>
  );
};
