import { useState } from 'react';
import { useFormik } from 'formik';
// import { useQueryClient } from 'react-query';
// import { useHistory } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import EmptyState from '../../common/images/Rectangle 752.svg';
import {
  FormDivStarter,
  // FormGroup,
  SplitInputDiv,
  // SubmitButton,
  ErrorMessage,
  Label,
  Title,
  SubText,
  ButtonWrapper,
  ContinueBtn,
  BackBtn,
  BigTitle,
  RateContainer,
  WhiteChipText,
  RateSubText
} from './styled.forms';
import { Box, TextField, Typography } from '@material-ui/core';
import { Countries, States } from './SelectFields';
import withWidth from '@material-ui/core/withWidth';
import { validateBusinessCreation } from './validator';
import BusinessLogo from './BusinessLogo';
import People from './People';
import Currencies from './SelectFields/Currencies';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import PeopleImg from '../../imageAssets/people.svg';
import { useHistory } from 'react-router-dom';
import { Country, State } from 'country-state-city';
import Packages from './Packages';
import { useSnackbar } from 'notistack';

const initialValues = {
  name: '',
  first_name: '',
  last_name: '',
  description: '',
  type: 'partnership',
  phone_number: '',
  address1: '',
  address2: '',
  country: 'NG',
  state: '',
  city: '',
  currency: 'NGN'
};

const countryList = ['Canada', 'United States', 'United Kingdom'];

function BusinessRegistration({
  width,
  handleSubmit,
  isLoading,
  products,
  setProducts
}) {
  const [isError, setIsError] = useState(false);

  const [stage, setStage] = useState(0);
  const [imageData, setImageData] = useState({
    fileupload: EmptyState,
    file: ''
  });
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  // const handleSubmit = async (payload) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await createBusiness(payload);
  //     if (response) {
  //       const BusinessCreate = setInterval(async () => {
  //         const newBusiness = await checkCompanyAddStatus(response);
  //         if (newBusiness.status === 'SUCCESS') {
  //           setIsLoading(false);
  //           clearInterval(BusinessCreate);
  //           queryClient.refetchQueries(['user']);
  //           queryClient.refetchQueries(['business-detail']);
  //           localStorage.setItem(
  //             'business_domain',
  //             newBusiness?.data.business_id
  //           );
  //           setIsLoading(false);
  //           // window.location.href = '/dashboard';
  //         }
  //       }, 5000);
  //     }
  //     // setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //     setIsError(true);
  //   }
  // };

  const formik = useFormik({
    initialValues,
    validate: validateBusinessCreation,
    onSubmit: handleSubmit
  });

  const hasErrors = (field) => {
    return formik.touched[field] && formik.errors[field];
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsError(false);
  };

  // if (isLoading) {
  //   return (
  //     <Box className={classes.loadingContainer}>
  //       <Box className={classes.loadingWrapper}>
  //         <img
  //           src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638526892/Bizedge_Frontend_Assets/BizLoader_dzmb4i.gif'
  //           style={{ width: '140px', height: '140px' }}
  //           alt=''
  //         />
  //         <Typography variant='h5'>SETTING UP YOUR BUSINESS ACCOUNT</Typography>
  //         <Typography variant='subtitle2'>
  //           Hold on a moment, while we get things in order for you and ensure
  //           you're good to go!
  //         </Typography>
  //       </Box>
  //     </Box>
  //   );
  // }

  // const handleCheckInput = (e) => {
  //   const regEx = /^[0-9\b]+$/;
  //   const { name, value } = e.target;

  //   if (e.target.value === '' || regEx.test(e.target.value)) {
  //     if (name === 'phone_number') {
  //       formik.setFieldValue('phone_number', value);
  //     }
  //   }
  // };

  const handleContinue = () => {
    if (stage === 8) {
      formik.handleSubmit();

      return;
    }
    if (stage === 6 && formik.values.size === undefined) {
      enqueueSnackbar(`Please select number of people`, { variant: 'error' });
      return;
    }
    setStage((prev) => prev + 1);
  };

  const handleBack = () => {
    if (stage === 0) {
      return;
    }
    setStage((prev) => prev - 1);
  };

  const isDisabled = () => {
    if (
      stage === 0 &&
      (formik.values.name === '' || formik.values.country === '')
    ) {
      return true;
    }
    if (
      stage === 1 &&
      (formik.values.state === '' ||
        formik.values.city === '' ||
        formik.values.address1 === '')
    ) {
      return true;
    }
    if (stage === 2 && formik.values.currency === '') {
      return true;
    }
    if (
      stage === 3 &&
      (formik.values.last_name === '' ||
        formik.values.first_name === '' ||
        formik.values.phone_number === '')
    ) {
      return true;
    }

    // if (stage === 6 && formik.values.size === undefined) {
    //   console.log('same', formik.values.size);
    //   return true;
    // }
    // if (stage === 6 && formik.values.state === 'point_of_contact') {
    //   return true;
    // }
    return false;
  };

  return (
    <>
      <FormDivStarter scroll={true} stage={stage}>
        {/* <FormHeader>Business Information</FormHeader>
        <FormDescription>
          Almost there! Please fill in your business information to register
          your business
        </FormDescription> */}
        <div className='create-business-menu'>
          <ArrowBackIcon onClick={() => handleBack()} className='back-arrow' />
          {stage !== 8 && (
            <>
              <Typography variant='h1' color='initial' className='create'>
                Create your business
              </Typography>
              <Button
                variant='text'
                className='close'
                onClick={() => history.push('/login')}
              >
                Close
              </Button>
            </>
          )}
        </div>
        {stage === 0 && (
          <>
            <Box>
              <Box my={4}>
                <Typography
                  color='initial'
                  className='create'
                  style={{
                    fontFamily: 'Black Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '24px',
                    lineHeight: '138.55%',
                    color: '#A8A8A8'
                  }}
                >
                  Create your business
                </Typography>
              </Box>
              <Title>What's your business name?</Title>

              <TextField
                variant='outlined'
                error={hasErrors('name')}
                color='secondary'
                fullWidth={true}
                label='Business name'
                // size={isWidthDown('lg', width) ? 'small' : 'medium'}
                size='small'
                placeholder='Business Name'
                type='text'
                name='name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {hasErrors('name') ? (
                <ErrorMessage>{formik.errors.name}</ErrorMessage>
              ) : null}

              <SubText>E.g microsoft, A&B Furniture</SubText>
            </Box>

            <Box mt={2}>
              <Title>What country is your business located?</Title>
              <Countries
                label='Country'
                name='country'
                onChange={(e) => {
                  formik.setFieldValue('country', e.target.value);
                  formik.setFieldValue(
                    'currency',
                    Country.getAllCountries().find(
                      (c) => c.isoCode === e.target.value
                    ).currency
                  );
                }}
                value={formik.values.country}
              />
              {hasErrors('country') ? (
                <ErrorMessage>{formik.errors.country}</ErrorMessage>
              ) : null}
              <SubText>E.g UK, Ghana</SubText>
            </Box>
          </>
        )}

        {stage === 1 && (
          <>
            <Box>
              <Title>Where's your business Location?</Title>
              <SplitInputDiv>
                <Label>
                  <States
                    value={formik.values.state}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={hasErrors('state')}
                    states={State.getStatesOfCountry(
                      Country.getAllCountries().find(
                        (c) =>
                          c.isoCode.toLowerCase() ===
                          formik.values.country?.toLowerCase()
                      )?.isoCode
                    )}
                  />
                  {hasErrors('state') ? (
                    <ErrorMessage>{formik.errors.state}</ErrorMessage>
                  ) : null}
                </Label>
                <Label>
                  <TextField
                    variant='outlined'
                    error={hasErrors('city')}
                    color='secondary'
                    fullWidth={true}
                    label='City'
                    // size={isWidthDown('lg', width) ? 'small' : 'medium'}
                    size='small'
                    placeholder='City'
                    type='text'
                    name='city'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    style={{ margin: 0 }}
                  />
                  {hasErrors('city') ? (
                    <ErrorMessage>{formik.errors.city}</ErrorMessage>
                  ) : null}
                </Label>
              </SplitInputDiv>
              {/* <SubText>
                E.g 1, Olaniyan close off Mobolaji Banks Anthony
              </SubText> */}
            </Box>

            <Box mt={1}>
              <Title>What’s your business address?</Title>
              <TextField
                variant='outlined'
                error={hasErrors('address1')}
                color='secondary'
                fullWidth={true}
                label='Address'
                size='small'
                placeholder='Address'
                type='text'
                name='address1'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address1}
              />
              {hasErrors('address1') ? (
                <ErrorMessage>{formik.errors.address1}</ErrorMessage>
              ) : null}
              <TextField
                variant='outlined'
                error={hasErrors('address2')}
                color='secondary'
                fullWidth={true}
                label='Address line 2 (Optional)'
                size='small'
                placeholder='Address line 2 (Optional)'
                type='text'
                name='address2'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address2}
              />

              {countryList.includes(formik.values.country) && (
                <TextField
                  variant='outlined'
                  error={hasErrors('address2')}
                  color='secondary'
                  fullWidth={true}
                  label={`${
                    formik.values.country === countryList[0]
                      ? 'Postal'
                      : formik.values.country === countryList[2]
                      ? 'Post'
                      : 'Zip'
                  } Code`}
                  size='small'
                  placeholder={`${
                    formik.values.country === countryList[0]
                      ? 'Postal'
                      : formik.values.country === countryList[2]
                      ? 'Post'
                      : 'Zip'
                  } Code`}
                  type='text'
                  name='address2'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address1}
                />
              )}
            </Box>
          </>
        )}

        {stage === 2 && (
          <Box>
            <Title>Which currency do you want to use on bizedge?</Title>
            <Currencies
              value={formik.values.currency}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={hasErrors('currency')}
            />
          </Box>
        )}

        {stage === 3 && (
          <Box>
            <Title>What's your name?</Title>
            <SplitInputDiv>
              <Box>
                <TextField
                  variant='outlined'
                  error={hasErrors('first_name')}
                  color='secondary'
                  fullWidth={true}
                  label='First name'
                  // size={isWidthDown('lg', width) ? 'small' : 'medium'}
                  size='small'
                  placeholder='First Name'
                  type='text'
                  name='first_name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                />
                {hasErrors('first_name') ? (
                  <ErrorMessage>{formik.errors.first_name}</ErrorMessage>
                ) : null}
              </Box>
              <Box>
                <TextField
                  variant='outlined'
                  error={hasErrors('last_name')}
                  color='secondary'
                  fullWidth={true}
                  label='Last name'
                  // size={isWidthDown('lg', width) ? 'small' : 'medium'}
                  size='small'
                  placeholder='Last Name'
                  type='text'
                  name='last_name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.last_name}
                />
                {hasErrors('last_name') ? (
                  <ErrorMessage>{formik.errors.last_name}</ErrorMessage>
                ) : null}
              </Box>
            </SplitInputDiv>

            <SubText>This is the name of the account admin</SubText>
            <Box my={1}></Box>
            <TextField
              variant='outlined'
              error={hasErrors('phone_number')}
              color='secondary'
              fullWidth={true}
              label='Phone Number'
              // size={isWidthDown('lg', width) ? 'small' : 'medium'}
              size='small'
              placeholder='Phone Number'
              type='text'
              name='phone_number'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone_number}
            />

            {hasErrors('phone_number') ? (
              <ErrorMessage>{formik.errors.phone_number}</ErrorMessage>
            ) : null}
            <SubText>Format: +2348012345678, 08012345678</SubText>
          </Box>
        )}
        {stage === 4 && (
          <Box>
            <Title>
              Do you have a business logo? <span>(optional)</span>{' '}
            </Title>
            <BusinessLogo
              formik={formik}
              EmptyState={EmptyState}
              imageData={imageData}
              setImageData={setImageData}
            />
          </Box>
        )}
        {stage === 5 && (
          <Box>
            <Title>
              What are you interested in? <span>(optional)</span>
            </Title>
            <Typography
              sx={{
                fontFamily: 'Black  Sans',
                fontSize: '14px',
                color: '#878787',
                marginBottom: '25px'
              }}
            >
              You can select more than one option.
            </Typography>
            <Packages {...{ products, setProducts }} />
          </Box>
        )}
        {stage === 6 && (
          <Box>
            <Title>How many people do you have in company?</Title>
            <People formik={formik} />
          </Box>
        )}

        {stage === 7 && (
          <Box>
            <Title>
              How did you know about us? <span>(optional)</span>
            </Title>
            <People isSocials formik={formik} />
          </Box>
        )}

        {stage === 8 && (
          <Box>
            <div className='img-container'>
              <img src={PeopleImg} alt='people' className='rateImage' />
            </div>
            <Title>
              <span>Welcome to the Future of work ,</span>
            </Title>
            <BigTitle>{formik.values.name}</BigTitle>
            <RateContainer>
              <div className='rate'>
                How would you rate your overall onboarding experience?
              </div>
              <Box display='flex' style={{ gap: 11 }} flexWrap='wrap'>
                {ratings.map((item) => (
                  <WhiteChipText
                    active={formik.values.rating === item.value}
                    onClick={(e) => {
                      formik.setFieldValue('rating', item.value);
                    }}
                  >
                    {item.title}
                  </WhiteChipText>
                ))}
              </Box>
            </RateContainer>
            <RateSubText>Good to have you here!</RateSubText>
          </Box>
        )}
        <Box marginTop='48px'></Box>
        <ButtonWrapper>
          <Box>
            <BackBtn onClick={() => handleBack()}>Back</BackBtn>
            <ContinueBtn
              variant='outlined'
              onClick={() => handleContinue()}
              disabled={isDisabled()}
            >
              {stage === 8 ? 'Lets go' : 'Continue'}
            </ContinueBtn>
          </Box>
          <div className='stage'>
            <span>{stage + 1}</span> of 9
          </div>
        </ButtonWrapper>
      </FormDivStarter>
      <Snackbar
        open={isError}
        onClose={handleClose}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MuiAlert
          onClose={handleClose}
          severity='error'
          elevation={6}
          variant='filled'
        >
          An error occcured, Please try again
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default withWidth()(BusinessRegistration);

const ratings = [
  {
    title: 'Not Good',
    value: 'not_good'
  },
  {
    title: 'Fairly Good',
    value: 'fairly_good'
  },
  {
    title: 'Neutral',
    value: 'neutral'
  },
  {
    title: 'Great',
    value: 'great'
  },
  {
    title: 'Very Great',
    value: 'very_great'
  }
];
